import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import useMediaQuery from '@mui/material/useMediaQuery'
import Popper from '@mui/material/Popper'
import { useTheme, styled } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Identifier, useDataProvider } from 'react-admin'
import { ValueEditorProps } from 'react-querybuilder'
import { Checkbox } from '@mui/material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const selectAllOption = [{ name: 'Select All', id: -1 }]

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  const { key, ...optionProps } = dataSet[0]

  const isChecked = key === -1 ? dataSet[3] === dataSet[4] : dataSet[2].selected

  return (
    <Typography
      key={key}
      component="li"
      {...optionProps}
      noWrap
      style={inlineStyle}
    >
      <Checkbox
        sx={{ '&.MuiCheckbox-root': { padding: 'unset' } }}
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={isChecked}
      />
      {dataSet[1]}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactElement<unknown>[] = []
  ;(children as React.ReactElement<unknown>[]).forEach(
    (
      item: React.ReactElement<unknown> & {
        children?: React.ReactElement<unknown>[]
      }
    ) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.length * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

// export default function VirtualizedAutoComplete(props: ValueEditorProps) {
export default function VirtualizedAutoComplete({
  value,
  label,
  filter = null,
  handleOnChange,
  dpFetchValue,
}: {
  value: ValueEditorProps['value']
  label: string
  filter?: any
  handleOnChange: ValueEditorProps['handleOnChange']
  dpFetchValue: string
}) {
  const [loading, setLoading] = useState(true)
  const [dataLoading, setDataLoading] = useState(true)
  const [options, setOptions] = useState<any[]>([])
  const dp = useDataProvider()
  const [selectedValues, setSelectedValues] = useState<any[]>([])

  const fetchOptions = useCallback(() => {
    setLoading(true)
    dp.getList(dpFetchValue, {
      filter,
      sort: { field: 'name', order: 'asc' },
      pagination: { page: 1, perPage: 10000 },
    }).then((response) => {
      setOptions(response.data)
      setLoading(false)
    })
    if (value && value.length) {
      setDataLoading(true)
      dp.getMany(dpFetchValue, { ids: value.join(',') }).then(
        (response: any) => {
          setSelectedValues(response.data)
          setDataLoading(false)
        }
      )
    } else {
      setDataLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label])

  useEffect(() => {
    setSelectedValues([])
    setOptions([])
    fetchOptions()
  }, [fetchOptions, label])

  const optionsWithSelectAll = useMemo(() => {
    if (options.length) {
      return selectAllOption.concat(options)
    }
    return []
  }, [options]) as any[]

  const onSelectAll = () => {
    if (selectedValues.length === options.length) {
      setSelectedValues([])
      handleOnChange([])
    } else {
      setSelectedValues(options)
      handleOnChange(options.map((val) => val.id))
    }
  }

  const onChange = (_, changeValue: any[]) => {
    if (changeValue.find((tag) => tag.id === -1)) {
      onSelectAll()
    } else {
      setSelectedValues(changeValue)
      handleOnChange(changeValue.map((val) => val.id))
    }
  }

  const renderTags = (tagValue: string[]) => {
    const numTags = tagValue.length
    return <span className="opacity-60 italic">{`${numTags} Selected`}</span>
  }

  const inputTextPlaceholder = !selectedValues.length
    ? label || 'Vendors'
    : null

  const renderInput = (params) => (
    <TextField
      {...params}
      variant="standard"
      label={loading || dataLoading ? 'Loading...' : 'Multiple values'}
      placeholder={inputTextPlaceholder}
    />
  )

  return (
    <Autocomplete
      multiple
      id="autocomplete-virtualized"
      sx={{ width: 300 }}
      disableListWrap
      disabled={loading || dataLoading}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={optionsWithSelectAll}
      onChange={onChange}
      value={selectedValues}
      renderInput={renderInput}
      getOptionLabel={(option: any) => option.name}
      isOptionEqualToValue={(option: any, testValue: any) =>
        option.id === testValue.id
      }
      renderOption={(renderOptionProps, option, state) => {
        return [
          { ...renderOptionProps, key: option.id },
          option.name,
          state,
          selectedValues.length,
          options.length,
        ] as React.ReactNode
      }}
      renderTags={renderTags}
      fullWidth
      disableCloseOnSelect
      style={{ minWidth: '500px', maxWidth: '500px' }}
    />
  )
}
